/* global firebase moment */

(function () {
	'use strict';

	angular
		.module('common')
		.factory('firebaseIO', [
			'$q',
			'$http',
			'seedcodeCalendar',
			'urlParameters',
			firebaseIO,
		])
		.factory('daybackIO', [
			'$q',
			'$http',
			'$translate',
			'manageUser',
			'seedcodeCalendar',
			'utilities',
			'firebaseIO',
			'onboardingTemplate',
			'dataStore',
			'hash',
			'manageTheme',
			'environment',
			'urlParameters',
			daybackIO,
		])
		.factory('manageSettings', [
			'$sce',
			'seedcodeCalendar',
			'daybackIO',
			'firebaseIO',
			'csvData',
			'utilities',
			'dataStore',
			'environment',
			manageSettings,
		]);

	function firebaseIO($q, $http, seedcodeCalendar, urlParameters) {
		// Static config cache
		const staticConfigCache = {};

		// Initialize Firebase
		const config = {};
		if (urlParameters?.dbRegion === 'asia') {
			config.apiKey = _CONFIG.FIREBASE_ASIA_API_KEY;
			config.authDomain = _CONFIG.FIREBASE_ASIA_AUTH_DOMAIN;
			config.databaseURL = _CONFIG.FIREBASE_ASIA_DATABASE_URL;
			config.projectId = _CONFIG.FIREBASE_PROJECT_ID;
		} else {
			config.apiKey = _CONFIG.FIREBASE_API_KEY;
			config.authDomain = _CONFIG.FIREBASE_AUTH_DOMAIN;
			config.databaseURL = _CONFIG.FIREBASE_DATABASE_URL;
			config.projectId = _CONFIG.FIREBASE_PROJECT_ID;
		}

		firebase.initializeApp(config);

		return {
			setUserData: setUserData,
			setGroupData: setGroupData,
			setUserSettingsData: setUserSettingsData,

			getUserData: getUserData,
			getGroupData: getGroupData,
			getOtherGroupData: getOtherGroupData,
			getUserSettingsData: getUserSettingsData,

			setData: setData,
			updateData: updateData,

			getData: getData,
			getStaticData: getStaticData,

			clearDraftSettings: clearDraftSettings,
		};

		//Update user data properties
		function setUserData(
			userID,
			path,
			property,
			data,
			update,
			callback,
			mutate
		) {
			var fullPath;
			var getUser = seedcodeCalendar.get('userPromises').getUser();
			getUser.then(function (user) {
				executeRequest(user);
			});

			function executeRequest(user) {
				if (!userID) {
					userID = user ? user.id : null;
				}

				if (!path && !property) {
					fullPath = 'users/';
					property = userID;
				} else {
					fullPath = 'users/' + userID + '/' + path;
				}

				if (update) {
					updateData(fullPath, property, data, callback, null, null);
				} else {
					setData(fullPath, property, data, callback, null, null);
				}
			}
		}

		//Update user settings data properties
		function setUserSettingsData(
			userID,
			path,
			property,
			data,
			update,
			callback,
			mutate
		) {
			var fullPath;
			var getUser = seedcodeCalendar.get('userPromises').getUser();
			getUser.then(function (user) {
				executeRequest(user);
			});

			function executeRequest(user) {
				if (!userID) {
					userID = user ? user.id : null;
				}

				if (!path && !property) {
					fullPath = 'userSettings/';
					property = userID;
				} else {
					fullPath = 'userSettings/' + userID + '/' + path;
				}

				if (update) {
					updateData(fullPath, property, data, callback, null, null);
				} else {
					setData(fullPath, property, data, callback, null, null);
				}
			}
		}

		//Update group data properties
		function setGroupData(path, property, data, update, callback, mutate) {
			return new Promise((resolve, reject) => {
				var getUser = seedcodeCalendar.get('userPromises').getUser();
				getUser.then(function (result) {
					executeRequest(result);
				});

				function executeRequest(user) {
					var fullPath;
					path = !path ? '' : path + '/';

					if (!path && !property) {
						fullPath = 'groups/';
						property = user.group.id;
					} else {
						fullPath = 'groups/' + user.group.id + '/' + path;
					}

					if (Array.isArray(data) && update) {
						setArray(fullPath, property, data, null)
							.then((result) => {
								if (callback) {
									callback(result);
								}
								resolve(result);
							})
							.catch((error) => {
								if (callback) {
									callback(error);
								}
								reject(error);
							});
					} else if (update) {
						updateData(fullPath, property, data, null, null, null)
							.then((result) => {
								if (callback) {
									callback(result);
								}
								resolve(result);
							})
							.catch((error) => {
								if (callback) {
									callback(error);
								}
								reject(error);
							});
					} else {
						setData(fullPath, property, data, null, null)
							.then((result) => {
								if (callback) {
									callback(result);
								}
								resolve(result);
							})
							.catch((error) => {
								if (callback) {
									callback(error);
								}
								reject(error);
							});
					}
				}
			});
		}

		function getGroupData(path, callback, mutate) {
			return new Promise((resolve, reject) => {
				var getUser = seedcodeCalendar.get('userPromises').getUser();
				getUser.then(function (user) {
					executeRequest(user);
				});

				function executeRequest(user) {
					var fullPath = 'groups/' + user.group.id + '/' + path;
					// Get the data on a post that has changed
					getData(fullPath, null, null, mutate)
						.then((result) => {
							if (callback) {
								callback(result);
							}
							resolve(result);
						})
						.catch((error) => {
							if (callback) {
								callback(error);
							}
							reject(error);
						});
				}
			});
		}

		function getOtherGroupData(groupID, path, callback, mutate) {
			const fullPath = 'groups/' + groupID + '/' + path;
			// Get the data on a post that has changed
			getData(fullPath, callback, null, mutate);
		}

		function getUserData(userID, path, callback, mutate) {
			return new Promise((resolve, reject) => {
				var getAuth = seedcodeCalendar.get('userPromises').getAuth();
				if (userID) {
					executeRequest();
				} else {
					getAuth
						.then(function (auth) {
							executeRequest(auth);
						})
						.catch(function (err) {
							if (callback) {
								callback(null);
							}
							reject();
						});
				}

				function executeRequest(auth) {
					if (!userID) {
						userID =
							auth && auth.signIn && auth.signIn.uid
								? auth.signIn.uid
								: auth
									? auth.uid
									: null;
					}

					var fullPath = 'users/' + userID + '/' + path;

					// Get the data on a post that has changed
					getData(fullPath, null, null, mutate)
						.then((result) => {
							if (callback) {
								callback(result);
							}
							resolve(result);
						})
						.catch((error) => {
							if (callback) {
								callback(error);
							}
							reject(error);
						});
				}
			});
		}

		function getUserSettingsData(userID, path, callback, mutate) {
			return new Promise((resolve, reject) => {
				var getAuth = seedcodeCalendar.get('userPromises').getAuth();
				if (userID) {
					executeRequest();
				} else {
					getAuth.then(function (auth) {
						executeRequest(auth);
					});
				}

				function executeRequest(auth) {
					if (!userID) {
						userID =
							auth && auth.signIn && auth.signIn.uid
								? auth.signIn.uid
								: auth
									? auth.uid
									: null;
					}

					var fullPath = 'userSettings/' + userID + '/' + path;
					// Get the data on a post that has changed
					getData(fullPath, null, null, mutate)
						.then((result) => {
							if (callback) {
								callback(result);
							}
							resolve(result);
						})
						.catch((error) => {
							if (callback) {
								callback(error);
							}
							reject(error);
						});
				}
			});
		}

		// Generic set data
		function setData(path, property, data, success, failure, mutate) {
			return new Promise((resolve, reject) => {
				const useStaticData = seedcodeCalendar.get('useStaticData');

				if (useStaticData) {
					if (success) {
						success(data);
					}
					return;
				}

				const draftSettingsMode =
					seedcodeCalendar.get('draftSettingsMode');

				if (draftSettingsMode) {
					const uid = seedcodeCalendar.get('uid');
					executeRequest(uid);
				} else {
					executeRequest();
				}

				function executeRequest(uid) {
					if (
						uid &&
						draftSettingsMode &&
						!draftSettingsIgnore(path)
					) {
						path = `draftSettings/${uid}/${draftSettingsMode}/${path}`;
					}

					if (data === undefined) {
						data = null;
					}

					for (const objProperty in data) {
						//Make sure we don't allow undefined as firebase will produce an error.
						if (data[objProperty] === undefined) {
							data[objProperty] = null;
						}
					}

					const usersRef = firebase
						.database()
						.ref(path)
						.child(property);
					usersRef
						.set(data)
						.then(function () {
							if (success) {
								success(data);
							}
							resolve(data);
						})
						.catch(function (error) {
							if (failure) {
								failure(error);
							}
							reject(error);
						});
				}
			});
		}

		// Generic update data
		function updateData(path, property, data, success, failure, mutate) {
			return new Promise((resolve, reject) => {
				var useStaticData = seedcodeCalendar.get('useStaticData');

				if (useStaticData) {
					if (success) {
						success(data);
					}
					return;
				}

				const draftSettingsMode =
					seedcodeCalendar.get('draftSettingsMode');

				if (draftSettingsMode) {
					executeRequest(seedcodeCalendar.get('uid'));
				} else {
					executeRequest();
				}

				function executeRequest(uid) {
					if (
						uid &&
						draftSettingsMode &&
						!draftSettingsIgnore(path)
					) {
						path = `draftSettings/${uid}/${draftSettingsMode}/${path}`;
					}

					var usersRef = firebase
						.database()
						.ref(path)
						.child(property);

					for (var objProperty in data) {
						//Make sure we don't allow undefined as firebase will produce an error.
						if (data[objProperty] === undefined) {
							data[objProperty] = null;
						}
					}

					usersRef
						.update(data)
						.then(function () {
							if (success) {
								success(data);
							}
							resolve(data);
						})
						.catch(function (error) {
							if (failure) {
								failure(error);
							}
							reject(error);
						});
				}
			});
		}

		// firebase.database().ref(path).once("value").then(function(data) {
		// 	var output = mutate ? mutate(data.val()) : data.val();
		// 	success(output);
		// }).catch(function(error) {
		// 	failure(errorObject);
		// });

		function getData(path, success, failure, mutate) {
			return new Promise((resolve, reject) => {
				var useStaticData = seedcodeCalendar.get('useStaticData');

				if (useStaticData) {
					getStaticData(path, success, failure, mutate)
						.then(function (data) {
							var output = mutate ? mutate(data) : data;
							if (success) {
								success(output);
							}
							resolve(output);
						})
						.catch(function (error) {
							console.log('error', error);
							if (failure) {
								failure(error);
							}
							reject(error);
						});
					return;
				}

				const draftSettingsMode =
					seedcodeCalendar.get('draftSettingsMode');

				if (draftSettingsMode) {
					executeRequest(seedcodeCalendar.get('uid'));
				} else {
					executeRequest();
				}

				function executeRequest(uid) {
					if (
						uid &&
						draftSettingsMode &&
						!draftSettingsIgnore(path)
					) {
						path = `draftSettings/${uid}/${draftSettingsMode}/${path}`;
					}

					firebase
						.database()
						.ref(path)
						.once('value')
						.then(function (data) {
							var output = mutate
								? mutate(data.val())
								: data.val();
							if (success) {
								success(output);
							}
							resolve(output);
						})
						.catch(function (error) {
							if (failure) {
								failure(error);
							}
							reject(error);
						});
				}
			});
		}

		function getStaticData(path, success, failure, mutate) {
			return new Promise((resolve, reject) => {
				const pathExplode = path.split('/');
				const parentNode = pathExplode[0];
				const targetNode = pathExplode[1];
				const privatePath =
					urlParameters?.staticConfigPath ||
					_CONFIG.DBK_PRIVATE_API_URL;

				// Look for cached version of data
				if (staticConfigCache?.[parentNode]?.[targetNode]) {
					const output = processResult(
						pathExplode,
						staticConfigCache[parentNode][targetNode],
						mutate
					);

					if (success) {
						success(output);
					}
					resolve(output);
					return;
				}

				// If not cached then fetch from source
				$http({
					method: 'GET',
					url: privatePath + parentNode + '/' + targetNode + '.json',
					data: {},
				})
					.then(function (result) {
						if (!result || !result.data) {
							if (failure) {
								failure('');
							}
							reject('');
							return;
						}

						const output = processResult(
							pathExplode,
							result.data,
							mutate
						);

						// Add data to cache if it doesn't exist
						if (!staticConfigCache[parentNode]) {
							staticConfigCache[parentNode] = {};
						}
						if (!staticConfigCache[parentNode][targetNode]) {
							staticConfigCache[parentNode][targetNode] =
								result.data;
						}

						if (success) {
							success(output);
						}
						resolve(output);
					})
					.catch(function (error) {
						//Run something on error
						if (error && error.status === 404) {
							if (success) {
								success();
							}
							resolve();
						} else {
							if (failure) {
								failure(error);
							}
							reject();
						}
					});
			});

			function processResult(pathElements, value, mutate) {
				for (let i = 2; i < pathElements.length; i++) {
					value = value[pathElements[i]];
					if (value === undefined) {
						return '';
					}
				}
				return mutate ? mutate(value) : value;
			}
		}

		function clearDraftSettings(id) {
			return new Promise((resolve, reject) => {
				if (!id) {
					reject();
					return;
				}
				const uid = seedcodeCalendar.get('uid');
				const path = `draftSettings/${uid}`;
				setData(path, id, null)
					.then((result) => {
						resolve(result);
					})
					.catch((error) => {
						reject(error);
					});
			});
		}

		/** @type {(path: string) => boolean} */
		function draftSettingsIgnore(path) {
			const ignorePaths = [
				'snapshots',
				'members',
				'shares',
				'events',
				'notifications',
			];
			const pathParts = path.split('/');

			for (const ignorePath of ignorePaths) {
				if (pathParts.includes(ignorePath)) {
					return true;
				}
			}
			return false;
		}

		function setArray(path, property, updateData, callback) {
			return new Promise((resolve, reject) => {
				const draftSettingsMode =
					seedcodeCalendar.get('draftSettingsMode');

				if (draftSettingsMode) {
					executeRequest(seedcodeCalendar.get('uid'));
				} else {
					executeRequest();
				}
				function executeRequest(uid) {
					if (
						uid &&
						draftSettingsMode &&
						!draftSettingsIgnore(path)
					) {
						path = `draftSettings/${uid}/${draftSettingsMode}/${path}`;
					}

					const fullPath = path + property;
					var matched = [];

					getData(fullPath)
						.then((result) => {
							onDataGetSuccess(result);
						})
						.catch((error) => {
							onDataGetFailure(error);
						});

					function onDataGetSuccess(data) {
						//Check for no data and force empty array
						if (!data) {
							data = [];
						}
						for (var i = 0; i < data.length; i++) {
							for (var ii = 0; ii < updateData.length; ii++) {
								if (data[i].id === updateData[ii].id) {
									matched.push(ii);
									//Remove item from our data
									if (updateData[ii].operation === 'delete') {
										data.splice(i, 1);
										continue;
									}
									//Otherwise update our data because this is an edit
									for (var objProperty in updateData[ii]) {
										data[i][objProperty] =
											updateData[ii][objProperty] ===
											undefined
												? null
												: updateData[ii][objProperty]; //Make sure we don't allow undefined as firebase will produce an error.
									}
								}
							}
						}

						//Remove matched items from updateData
						for (var i = 0; i < matched.length; i++) {
							updateData.splice(i, 1);
						}
						//Loop through any remaining items in update data and add them to our data source
						for (var i = 0; i < updateData.length; i++) {
							data.push(updateData[i]);
						}
						//Write the data to firebase
						setData(
							path,
							property,
							data,
							onDataSetSuccess,
							onDataSetFailure,
							null
						);
						setData(path, property, data)
							.then((result) => {
								onDataSetSuccess(result);
							})
							.catch((error) => {
								onDataSetFailure(error);
							});
					}
					function onDataGetFailure(error) {
						if (callback) {
							callback(false);
						}
						reject(error);
					}
					function onDataSetSuccess(updatedData) {
						if (callback) {
							callback(updatedData);
						}
						resolve(updatedData);
					}
					function onDataSetFailure(error) {
						if (callback) {
							callback(false);
						}
						reject(error);
					}
				}
			});
		}
	}

	function daybackIO(
		$q,
		$http,
		$translate,
		manageUser,
		seedcodeCalendar,
		utilities,
		firebaseIO,
		onboardingTemplate,
		dataStore,
		hash,
		manageTheme,
		environment,
		urlParameters
	) {
		var user = {};
		var userDeferred = $q.defer();

		var userPromises = seedcodeCalendar.init('userPromises', {
			getUser: function () {
				return userDeferred.promise;
			},
			getAuth: function () {
				return manageUser.getAuth();
			},
		});

		var onlineStatusSet;

		return {
			updateAllowedUserCount: updateAllowedUserCount,
			initializeOnlineStatus: initializeOnlineStatus,
			setSession: setSession,
			reset: reset,
			signIn: signIn,
			signOut: signOut,
			findUser: findUser,
			loadUser: loadUser,
			getUser: getUser,
			userExists: userExists,
			createUserData: createUserData,
			createMemberData: createMemberData,
			createShareConfig: createShareConfig,

			getShareData: getShareData,
			getEventData: getEventData,
			getSources: getSources,

			setShareData: setShareData,
			setEvents: setEvents,
			setEventData: setEventData,

			setNotificationUserData: setNotificationUserData,
			getNotificationUserData: getNotificationUserData,
			getUnscheduled: getUnscheduled,
			setUnscheduled: setUnscheduled,
		};

		function updateAllowedUserCount(
			groupID,
			subscriptionID,
			productHandle,
			count,
			isTrial,
			callback
		) {
			var settings = seedcodeCalendar.get('settings');
			var hashData = hash.get();
			var freeUserLimit = hashData.freeUserLimit || 0;
			var userLimit = hashData.userLimit || 1;

			var adjustedCount = Math.max(count - freeUserLimit, 1);

			if (userLimit - freeUserLimit === adjustedCount) {
				if (callback) {
					callback(settings ? settings.activation : {});
				}
				return;
			}

			// Run quantity adjustment which adjusts quantity on subscription and firebase
			$http({
				method: 'POST',
				url: _CONFIG.DBK_SUBSCRIPTION_URL + '/quantity',
				data: {
					isTrial: isTrial,
					groupID: groupID,
					subscriptionID: subscriptionID,
					productHandle: productHandle,
					quantity: adjustedCount,
				},
			})
				.then(function (response) {
					if ((!response || !response.data) && callback) {
						callback(false);
						return;
					}
					//Update count in activation
					if (settings && settings.activation) {
						settings.activation.usersPermitted = count;
						hash.update('userLimit', count);
					}
					if (callback) {
						callback(settings ? settings.activation : {});
					}
				})
				.catch(function (error) {
					//Run something on error
					if (callback) {
						callback(false);
					}
				});
		}

		// Online status
		function initializeOnlineStatus(isShare) {
			var useStaticData = seedcodeCalendar.get('useStaticData');

			if (
				useStaticData ||
				onlineStatusSet ||
				isShare ||
				fbk.isSalesforce()
			) {
				return;
			}
			var getUser = seedcodeCalendar.get('userPromises').getUser();

			getUser.then(function (user) {
				// Create a reference to the special '.info/connected' path in
				// Realtime Database. This path returns `true` when connected
				// and `false` when disconnected using snapshot.val()
				firebase
					.database()
					.ref('.info/connected')
					.on('value', setStatus);

				// Manage forced sign out when a user has too many sessions
				var signOutRef = firebase
					.database()
					.ref(
						'/sessions/' +
							user.group.id +
							'/' +
							user.id +
							'/forceSignOut'
					);
				signOutRef.on('value', signOutChanged);

				function signOutChanged(snapshot) {
					var signOutValue = snapshot.val();
					var uid = firebase.auth().currentUser.uid;
					var signOutValueMatch;
					var signOutValueResult;

					var deviceID = user.id + '-' + uid;

					if (!signOutValue) {
						return;
					}

					if (typeof signOutValue === 'object') {
						signOutValueMatch = signOutValue[deviceID];
						if (Object.keys(signOutValue).length === 1) {
							signOutValueResult = null;
						} else {
							delete signOutValue[deviceID];
							signOutValueResult = signOutValue;
						}
					} else {
						// Sign out value is a signle item string. This can be removed as it's legacy and no longer used after Jan 2022
						signOutValueMatch = signOutValue;
						signOutValueResult = null;
					}

					if (signOutValueMatch === deviceID) {
						signOutRef.off('value', signOutChanged);
						signOutRef
							.set(signOutValueResult)
							.then(function () {
								//Sign out
								signOut();
							})
							.catch(function (error) {
								console.log(error);
							});
					}
				}
			});
		}

		function setSession(groupID, userID, deviceID, value, callback) {
			firebaseIO.setData(
				'sessions/' + groupID + '/' + userID + '/session',
				deviceID,
				value,
				function (result) {
					if (callback) {
						callback(result);
					}
				},
				function (error) {
					if (callback) {
						callback(null);
					}
				},
				null
			);
		}
		function createDeviceID() {
			const uid = firebase.auth().currentUser?.uid;
			const userID = user?.id;
			return uid && userID ? `${userID}-${uid}` : null;
		}

		function setStatus(snapshot) {
			var useStaticData = seedcodeCalendar.get('useStaticData');
			var connected = snapshot.val();

			// If we're not currently connected, don't do anything.
			if (!connected || !user || !user.id || useStaticData) {
				return;
			}

			var savedDeviceSession = dataStore.getState(
				'deviceSession',
				false,
				false,
				true
			);

			var deviceID = createDeviceID();

			var userStatusDatabaseRef = firebase
				.database()
				.ref(`/sessions/${user.group.id}/${user.id}`);
			var userSessionRef = firebase
				.database()
				.ref(
					`/sessions/${user.group.id}/${user.id}/session/${deviceID}`
				);

			var sessionAdd = {};
			var sessionRemove = {};
			var lastChanged = firebase.database.ServerValue.TIMESTAMP;

			var now = new Date().getTime();

			seedcodeCalendar.init(
				'device',
				{id: deviceID, timestamp: now},
				true
			);

			if (savedDeviceSession && savedDeviceSession !== deviceID) {
				// Clean up old session records if the device id is different than the last stored value
				setSession(
					user.group.id,
					user.id,
					savedDeviceSession,
					null,
					function () {
						// Save the device id to local storage for comparison
						dataStore.saveState(
							'deviceSession',
							deviceID,
							false,
							false,
							true
						);
					}
				);
			} else if (!savedDeviceSession) {
				// Save the device id to local storage for comparison
				dataStore.saveState(
					'deviceSession',
					deviceID,
					false,
					false,
					true
				);
			}

			sessionRemove[deviceID] = null;
			sessionAdd[deviceID] = {
				id: deviceID,
				timestamp: lastChanged,
				userAgent: window.navigator.userAgent,
			};

			// Set the session record to the current device id
			setSession(
				user.group.id,
				user.id,
				deviceID,
				sessionAdd[deviceID],
				function () {
					// Save the device id to local storage for comparison
					dataStore.saveState(
						'deviceSession',
						deviceID,
						false,
						false,
						true
					);
				}
			);

			// We'll create two constants which we will write to
			// the Realtime database when this device is offline
			// or online.
			var isOfflineForDatabase = {
				lastTriggerState: 'offline',
				lastTriggeredBy: deviceID,
				lastChanged: lastChanged,
			};

			var isOnlineForDatabase = {
				lastTriggerState: 'online',
				lastTriggeredBy: deviceID,
				lastChanged: lastChanged,
			};

			onlineStatusSet = true;

			// If we are currently connected, then use the 'onDisconnect()'
			// method to add a set which will only trigger once this
			// client has disconnected by closing the app,
			// losing internet, or any other means.
			userStatusDatabaseRef
				.onDisconnect()
				.update(isOfflineForDatabase)
				.then(function () {
					// The promise returned from .onDisconnect().set() will
					// resolve as soon as the server acknowledges the onDisconnect()
					// request, NOT once we've actually disconnected:
					// https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

					// We can now safely set ourselves as 'online' knowing that the
					// server will mark us as offline once we lose connection.

					userStatusDatabaseRef.update(isOnlineForDatabase);
				});

			userSessionRef.onDisconnect().remove((err) => {
				// If there is an error instantiating the onDisconnect immediately delete the session record
				if (err && user) {
					setSession(user.group.id, user.id, deviceID, null, null);
				}
			});
		}

		function reset(init) {
			var useStaticData = seedcodeCalendar.get('useStaticData');

			if (useStaticData) {
				return;
			}

			// Reset user promise
			userDeferred = $q.defer();

			onlineStatusSet = null;

			// // Remove firebase connected listener
			firebase.database().ref('.info/connected').off('value', setStatus);

			// Remove all custom styles and themes
			manageTheme.removeAllCustomStyles(init);

			// Clear any source access tokens
			var sourceTemplates = seedcodeCalendar.get('sourceTemplates') || [];

			for (var i = 0; i < sourceTemplates.length; i++) {
				if (sourceTemplates[i].onSignOut) {
					sourceTemplates[i].onSignOut();
				}
			}

			if (user) {
				// Clear out all properties in the user object
				for (var property in user) {
					user[property] = null;
				}
			}

			// If we are resetting during init reset seedcodeCalendar variables (sign out will do this eslewhere)
			if (init) {
				seedcodeCalendar.reset();
			}
		}

		//We run this when the file loads, then wait for a promise which is resolved when fetchUser get's auth and retrieves the user record
		function loadUser(successCallback, rejectCallback) {
			var linkedAccount = manageUser.getLinkedAccount();
			var linkedGroupToken = urlParameters.userGroupToken;
			var linkedGroupID = linkedGroupToken
				? crypt.runGetHashProperty(linkedGroupToken, 'uid')
				: null;

			var linkedUserFirstName = urlParameters.userFirstName;
			var linkedUserLastName = urlParameters.userLastName;

			var linkedUserAdmin =
				urlParameters.userAdmin &&
				(urlParameters.userAdmin === 'true' ||
					urlParameters.userAdmin === 'false')
					? urlParameters.userAdmin === 'true'
						? true
						: false
					: undefined;
			var linkedUserManageFilters =
				urlParameters.userManageFilters &&
				(urlParameters.userManageFilters === 'true' ||
					urlParameters.userManageFilters === 'false')
					? urlParameters.userManageFilters === 'true'
						? true
						: false
					: undefined;
			var linkedUserReadOnly =
				urlParameters.userReadOnly &&
				(urlParameters.userReadOnly === 'true' ||
					urlParameters.userReadOnly === 'false')
					? urlParameters.userReadOnly === 'true'
						? true
						: false
					: undefined;
			var linkedUserAllowSharing =
				urlParameters.userAllowSharing &&
				(urlParameters.userAllowSharing === 'true' ||
					urlParameters.userAllowSharing === 'false')
					? urlParameters.userAllowSharing === 'true'
						? true
						: false
					: undefined;

			var translations = $translate.instant(
				[
					'There was a problem signing in',
					'Your current DayBack license does not support this feature',
					'Your subscription does not have enough user licenses',
					'There was an issue retrieving data from the specified group token',
					'Please contact your FileMaker developer',
				],
				{
					account: linkedAccount,
				}
			);

			// Let's set a function in manageuser that determines if this is an account auth and then we
			if (linkedAccount && linkedGroupID) {
				// Get parent group members
				firebaseIO.getData(
					'groups/' + linkedGroupID + '/members',
					function (members) {
						var userCount = 0;
						for (var property in members) {
							if (members[property].account === linkedAccount) {
								// Found match to member - sign in user
								fetchUser(
									userDeferred,
									successCallback,
									rejectCallback,
									members[property].userID
								);
								return userDeferred;
							}

							// Get total active user count - There is a function in  settings-servies.js called getActiveMemberCount that does the same thing
							// Can't use that here as it would create a circular reference. So we will recreate the functionality for this one thing.
							if (
								members[property].userID &&
								members[property].active !== false &&
								members[property].active !== 'false'
							) {
								userCount++;
							}
						}

						// No match for member check if the user exists in another group
						findUser(
							'account',
							linkedAccount,
							function (foundUser) {
								if (foundUser) {
									// User already exists in a different group
									var title =
										translations[
											'There was a problem signing in'
										];
									var message =
										'The email address is already in use by another account.';

									linkedUserError(
										title,
										message,
										rejectCallback,
										translations
									);
									return;
								}

								// No match for member and user doesn't exist - create account
								var password = utilities.generatePassword();

								// Adjust the user count to add 1
								userCount++;

								firebaseIO.getData(
									'groups/' + linkedGroupID + '/settings',
									function (settings) {
										var activation = settings.activation;

										if (!activation) {
											// Error getting account object
											var title =
												translations[
													'There was a problem signing in'
												];
											var message =
												translations[
													'Your current DayBack license does not support this feature'
												];

											linkedUserError(
												title,
												message,
												rejectCallback,
												translations
											);
											return;
										}

										var userLimit =
											activation.usersPermitted || 0;
										var freeUserLimit =
											activation.freeUsersPermitted || 0;
										var userLimitAdjusted =
											userLimit + freeUserLimit;

										hash.update(
											'userLimit',
											userLimitAdjusted
										);
										hash.update(
											'freeUserLimit',
											freeUserLimit
										);

										// Check if the user count can allow a new user
										if (
											settings.manageUserCount ||
											activation.state === 'trialing'
										) {
											updateAllowedUserCount(
												linkedGroupID,
												activation.subscriptionID,
												activation.productHandle,
												userCount,
												!activation.isSubscription,
												function (result) {
													if (result) {
														// firebaseIO.setData('groups/' + linkedGroupID + '/settings/activation', 'usersPermitted', userCount, function() {
														manageUser.createDayBackUser(
															linkedAccount,
															password,
															'',
															'',
															'',
															'invited',
															false,
															'',
															'email',
															true,
															true,
															accountCreated,
															true
														);
														//}, null);
													} else {
														// Error adding additional user license
														var title =
															translations[
																'There was a problem signing in'
															];
														var message =
															'There was an error attempting to add additional user licenses.';

														linkedUserError(
															title,
															message,
															rejectCallback,
															translations
														);
														return;
													}
												}
											);
										} else if (
											userLimitAdjusted &&
											userLimitAdjusted >= userCount
										) {
											manageUser.createDayBackUser(
												linkedAccount,
												password,
												'',
												'',
												'',
												'invited',
												false,
												'',
												'email',
												true,
												true,
												accountCreated,
												true
											);
										} else {
											// Not enough user licenses
											var title =
												translations[
													'There was a problem signing in'
												];
											var message =
												translations[
													'Your subscription does not have enough user licenses'
												];

											linkedUserError(
												title,
												message,
												rejectCallback,
												translations
											);
											return;
										}
									},
									function (error) {
										console.log('error', error);
										// Error getting account object
										var title =
											translations[
												'There was a problem signing in'
											];
										var message =
											translations[
												'Your current DayBack license does not support this feature'
											];

										linkedUserError(
											title,
											message,
											rejectCallback,
											translations
										);
									}
								);
							}
						);

						function accountCreated(result) {
							var title =
								translations['There was a problem signing in'];
							var message;
							if (!result || !result.user) {
								if (result && result.message) {
									message = result.message;
								}

								// There was an error creating the account
								// Sign out and show dialog
								linkedUserError(
									title,
									message,
									rejectCallback,
									translations
								);
								return;
							}

							var userProperties = {
								id: result.user.uid,
								account: linkedAccount,
								name: '',
								firstName: linkedUserFirstName,
								lastName: linkedUserLastName,
								email: linkedAccount,
								groupID: linkedGroupID,
								isAdmin:
									linkedUserAdmin === undefined
										? false
										: linkedUserAdmin,
								manageFilters: linkedUserManageFilters,
								readOnly: linkedUserReadOnly,
								allowSharing: linkedUserAllowSharing,
								userType: null,
							};
							createUserData(
								userProperties,
								true,
								true,
								false,
								function (result) {
									fetchUser(
										userDeferred,
										successCallback,
										rejectCallback,
										result.user.id
									);
								}
							);
						}

						return userDeferred;
					},
					function (error) {
						console.log('error', error);
						// Error getting members list
						var title =
							translations['There was a problem signing in'];
						var message =
							translations[
								'There was an issue retrieving data from the specified group token'
							];
						linkedUserError(
							title,
							message,
							rejectCallback,
							translations
						);
					}
				);
			} else {
				fetchUser(userDeferred, successCallback, rejectCallback);
				return userDeferred;
			}
		}

		function linkedUserError(title, message, callback, translations) {
			if (!translations) {
				translations = $translate.instant([
					title,
					message,
					'Please contact your FileMaker developer',
				]);
			}

			var platform = utilities.getDBKPlatform();
			if (platform === 'dbkfmjs' || platform === 'dbkfmwd') {
				message += ' ';
				message +=
					translations['Please contact your FileMaker developer'];
			}

			signOut(null, true);
			utilities.showModal(title, message);

			if (callback) {
				callback();
			}
		}

		//Checks for successful auth and retrieves user record
		function fetchUser(
			deferred,
			successCallback,
			rejectCallback,
			userIDOverride
		) {
			var auth;
			var userID;
			manageUser.getAuth().then(function (result) {
				auth = result;

				if (auth && userIDOverride) {
					// If we are overriding the signed in user we need to update the auth signIn property with that new userID
					auth.signIn.uid = userIDOverride;
				}

				userID =
					auth && auth.signIn && auth.signIn.uid
						? auth.signIn.uid
						: auth
							? auth.uid
							: null;

				// set uid to state
				seedcodeCalendar.init('uid', userID);

				if (auth.share) {
					getShareData(auth.share.id, processShare, null);
				} else {
					var lastCompareToken = dataStore.getState(
						'compareToken',
						null,
						null,
						true
					);
					dataStore.saveState(
						'compareToken',
						manageUser.createUserToken(null, auth.signIn.uid),
						null,
						null,
						true
					);

					// Reload in beta mode if necessary
					// Needs to happen after user id has been set as this is specific to a user
					// Check if on beta mode path
					const hasBetaModePath =
						window.location.href.includes('/beta/');
					const betaMode =
						hasBetaModePath ||
						dataStore.getState('betaMode', false, false, true);

					if (betaMode) {
						seedcodeCalendar.init('betaMode', true);
						dataStore
							.clearServiceWorkerCaches()
							.then((cachesCleared) => {
								if (!hasBetaModePath) {
									const originalUrl = new URL(
										window.location.href
									);
									const newUrl = new URL(
										originalUrl.origin +
											'/beta' +
											originalUrl.search +
											originalUrl.hash
									);
									window.location.replace(newUrl.toString());
								} else if (cachesCleared) {
									window.location.reload();
								}
							});
					}

					// check if we are in draft settings mode
					seedcodeCalendar.init(
						'draftSettingsMode',
						dataStore.getState(
							'draftSettingsMode',
							false,
							false,
							true
						)
					);

					if (manageUser.getLinkedAccount()) {
						// getLinkedAccount is for account in url login, getLinkedUserToken is for url userTokens and authorizing DBKFM share account

						if (
							!lastCompareToken ||
							crypt.runGetHashProperty(
								lastCompareToken,
								'uid'
							) !== auth.signIn.uid
						) {
							// Record that the linked token has changed so we can pick this up in an app action if need be
							seedcodeCalendar.init('linkedUserChanged', {
								lastCompareToken: lastCompareToken,
								lastCompareUID: lastCompareToken
									? crypt.runGetHashProperty(
											lastCompareToken,
											'uid'
										)
									: '',
								uid: auth.signIn.uid,
							});
							// Clear the data after a short duration so this doesn't persist for the rest of the session
							window.setTimeout(function () {
								seedcodeCalendar.init(
									'linkedUserChanged',
									null
								);
							}, 7000);

							// User compare token doesn't match and we are signing in with a token method
							manageUser.clearStoredAuthTokens(
								auth.signIn.uid,
								function () {
									// Successfully cleared tokens
									//Get user data
									firebaseIO.getUserData(
										userID,
										'user',
										onSuccess
									);
								},
								function () {
									// There was an error
									var title =
										'There was a problem signing in';
									var message =
										'There was an issue clearing user tokens';
									linkedUserError(
										title,
										message,
										rejectCallback
									);
								}
							);
						} else {
							// Token does match
							firebaseIO.getUserData(userID, 'user', onSuccess);
						}
					} else {
						// Not a token based sign in
						firebaseIO.getUserData(userID, 'user', onSuccess);
					}
				}
			});
			function processShare(share) {
				//Check if the share exists or has expired
				if (
					!share ||
					moment(share.expires).isBefore(moment()) ||
					(share.shareWith && share.shareWith !== 'public')
				) {
					if (rejectCallback) {
						rejectCallback('/share-expired');
					}
					deferred.reject(user);
					return;
				}
				seedcodeCalendar.init('share', share);
				if (auth.signIn.public) {
					user.id = share.userID;
					user.groupID = share.groupID;
				}
				firebaseIO.getUserData(user.id, 'user', onSuccess);
			}
			function onSuccess(result) {
				var userProperties;
				//Check if we have a valid account still (for example if we deleted their account manually) - if so log out.
				if (
					(!result && !environment.isSalesforce) ||
					(!result && (!auth.signIn || !auth.signIn.account)) ||
					(auth.signIn.authType === 'token' &&
						result &&
						result.email.toLowerCase() !==
							auth.signIn.account.toLowerCase() &&
						auth.signIn.account !== 'undefined')
				) {
					if (auth.share) {
						if (rejectCallback) {
							rejectCallback('/share-expired');
						}
						deferred.reject(user);
						return;
					} else {
						if (rejectCallback) {
							rejectCallback();
						}
						deferred.reject();
						signOut();
					}
					return;
				}
				//If there is currently no user record pass in our user data. This could happen when we don't go through our email onboardingTemplate process ie. salesforce
				else if (!result) {
					userProperties = {
						id: auth.signIn.uid,
						account: auth.signIn.account,
						name: auth.signIn.name,
						email: auth.signIn.email,
						groupID: auth.signIn.groupID,
					};

					createUserData(
						userProperties,
						true,
						true,
						null,
						processResult
					);
				}
				//If we found a user record process the user data
				else {
					processResult(result);
				}

				function processResult(userData) {
					// Add user id and email to root of the user object. This could come in missing when onboarding
					if (!userData.id) {
						userData.id = userData.user.id;
					}
					if (!userData.email) {
						userData.email = userData.user.email;
					}

					user = userData;
					user.auth = auth;
					user.group.token = manageUser.createGroupToken(
						user.group.memberID,
						user.group.id
					);
					deferred.resolve(user);
					if (successCallback) {
						successCallback(user);
					}
				}
			}
		}

		//Returns the stored user object
		function getUser() {
			return user;
		}

		function userExists(userID, callback) {
			firebaseIO.getUserData(
				userID,
				'user',
				function (user) {
					if (user) {
						callback(user);
					} else {
						callback(null);
					}
				},
				null
			);
		}

		function signIn(username, password, remember, callback, isAuthed) {
			var auth;

			if (isAuthed) {
				//If we don't want to auth a new user because we have already authenticated
				var authData = manageUser.getAuth();
				authData.then(function (data) {
					onAuth(data);
				});
			} else {
				//Clear any stored user data
				reset();
				manageUser.auth(username, password, remember, onAuth, true);
			}
			function onAuth(result) {
				if (result.error) {
					callback(result);
				} else {
					auth = result;
					//Re-fetch our user data
					fetchUser(userDeferred);
					var getUser = userDeferred.promise;
					getUser.then(function (result) {
						onUserLoad(result);
					});
				}
			}
			function onUserLoad(result) {
				if (result && result.changePassword) {
					//Remove the change password flag from the user profile
					firebaseIO.setUserData(
						result.id,
						'user',
						'changePassword',
						null,
						false,
						null,
						null
					);
					//Set auth object data to trigger a password change
					auth.user.signIn.isTemporaryPassword = true;
					auth.user.signIn.password = password;
				}
				if (callback) {
					callback(auth);
				}
			}
		}

		function createShareConfig(config) {
			var share = seedcodeCalendar.get('share');
			if (!share) {
				return;
			}

			//Set read only and admin settings for share
			config.accountName = 'Public Share';
			config.showAdminSettings = false;
			config.readOnly = true;
			config.admin = false;
			config.sharePrivileges = null;
		}

		function publicSourceCreate() {
			var sources = {};
			sources['shareGoogle'] = {
				id: 'shareGoogle',
				isPrimary: false,
				name: 'Google Calendar',
				readOnly: true,
				sourceTypeID: 3,
			};
			return sources;
		}

		function sharedSourceCreate(share) {
			if (!share) {
				return false;
			}
			var source = {
				id: 'shareDayBack',
				isPrimary: false,
				name: share.name,
				readOnly: true,
				sourceTypeID: 6,
				share: share,
			};

			return source;
		}

		function getSources(callback) {
			var auth = user.auth;

			firebaseIO.getGroupData('sources', processSources);

			function processSources(sources) {
				var share = seedcodeCalendar.get('share');

				var result;
				var publicSources;
				var specifiedSources;
				var sharedSource = sharedSourceCreate(share);

				if (sharedSource) {
					result = {};
					result.shareDayBack = sharedSource;
					if (share.platform === 'dbkfm') {
						//We specify our source schedules for DBKFM so let's use those in this case
						specifiedSources = {};
						//Start at 1 because FM sources start at 1
						for (var i = 1; i < share.schedules.length; i++) {
							//Set DBKFM source type id if this field is blank
							if (!share.schedules[i].sourceTypeID) {
								share.schedules[i].sourceTypeID = 1;
							}
							specifiedSources[share.schedules[i].id] =
								share.schedules[i];
						}
						seedcodeCalendar.init(
							'shareSources',
							JSON.parse(JSON.stringify(specifiedSources))
						);
					} else {
						seedcodeCalendar.init('shareSources', sources);
					}
				} else {
					result = sources;
				}

				callback(result);
			}
		}

		function signOut(callback, preventLocationChange, fromSignOut) {
			if (
				manageUser.getLinkedAccount() ||
				manageUser.getLinkedUserToken()
			) {
				utilities.showModal(
					'Cannot Sign Out',
					'You cannot sign out of an account that is automatically signed in',
					null,
					null,
					'OK',
					null
				);
				return;
			}

			var deviceID = createDeviceID();
			// Clear existing session record before deauthorizing user
			if (deviceID) {
				setSession(user.group.id, user.id, deviceID, null, null);
			}

			// Deauth user
			manageUser.logout(onSignOut, preventLocationChange, fromSignOut);

			function onSignOut(result) {
				try {
					//Clear stored globals
					reset();
				} catch (error) {
					console.log('No user or group exists - ' + error);
				}

				//Callback
				if (callback) {
					callback(result);
				}
			}
		}

		//Used to retrieve user data
		function findUser(queryProperty, queryValue, callback) {
			var useStaticData = seedcodeCalendar.get('useStaticData');

			if (useStaticData) {
				callback();
				return;
			}

			firebase
				.database()
				.ref('/users')
				.orderByChild(queryProperty)
				.equalTo(queryValue)
				.once('value')
				.then(function (result) {
					callback(result.val());
				})
				.catch(function (error) {
					console.log('error', error);
					//Do something if an error occurred
				});
		}

		function createMemberData(
			memberID,
			account,
			userID,
			firstName,
			lastName,
			isAdmin,
			manageFilters,
			readOnly,
			allowSharing
		) {
			return {
				id: memberID,
				account: account || '',
				admin: isAdmin === undefined ? true : isAdmin,
				userID: userID,
				firstName: firstName || '',
				lastName: lastName || '',

				manageFilters:
					manageFilters === undefined ? null : manageFilters,
				readOnly: readOnly === undefined ? null : readOnly,
				allowSharing: allowSharing === undefined ? null : allowSharing,
			};
		}

		//Create new user in backend this is used on first login so we create a user profile in our system and join them to a group (this is user data not an account)
		function createUserData(
			userData,
			addAsMember,
			createMember,
			changePassword,
			callback
		) {
			var parentGroupToken = urlParameters.settingsToken;
			var parentGroupID = parentGroupToken
				? crypt.runGetHashProperty(parentGroupToken, 'uid')
				: null;

			onboardingTemplate
				.initialize(
					userData.userType,
					userData.id,
					parentGroupID,
					addAsMember
				)
				.then(function (onboardingData) {
					onboardingData.user = userData;
					if (parentGroupID && (!addAsMember || fbk.isSalesforce())) {
						firebaseIO.getData(
							'groups/' + parentGroupID,
							function (group) {
								if (group && group.settings) {
									group.settings.activation =
										onboardingData.settings.activation;
									group.settings.isVertical = null;
								}
								processUserData(onboardingData, group);
							},
							function (error) {
								processUserData(onboardingData);
							},
							null
						);
					} else {
						processUserData(onboardingData);
					}
				});
			function processUserData(onboardingData, groupDataOverride) {
				var memberID = utilities.generateUID();
				var data = {
					account: onboardingData.user.account.toLowerCase(),
					user: {
						id: onboardingData.user.id,
						email:
							onboardingData.user.email ||
							onboardingData.user.account,
						name: onboardingData.user.name || '',
						group: {
							id:
								onboardingData.user.groupID ||
								onboardingData.user.id,
							memberID: memberID,
						},
					},
				};
				if (changePassword) {
					data.user.changePassword = true;
				}
				var groupData = {
					group: {
						id:
							onboardingData.user.groupID ||
							onboardingData.user.id,
						name: onboardingData.user.groupName
							? onboardingData.user.groupName
							: null,
						signupRoute: userData.userType
							? userData.userType
							: null,
						memberID: memberID,
					},
					members: {},
					settings: groupDataOverride
						? groupDataOverride.settings || null
						: onboardingData.settings,
					sources: groupDataOverride
						? groupDataOverride.sources || null
						: onboardingData.sources,
					resources: groupDataOverride
						? groupDataOverride.resources || null
						: onboardingData.resources,
					statuses: groupDataOverride
						? groupDataOverride.statuses || null
						: onboardingData.statuses,
					theme: groupDataOverride
						? groupDataOverride.theme || null
						: onboardingData.theme || null,
					calendarActions: groupDataOverride
						? groupDataOverride.calendarActions || null
						: onboardingData.calendarActions || null,
				};

				if (groupDataOverride && groupDataOverride.translations) {
					groupData.translations = groupDataOverride.translations;
				}
				if (groupDataOverride && groupDataOverride.calendarActions) {
					groupData.calendarActions =
						groupDataOverride.calendarActions;
				}

				groupData.members[memberID] = createMemberData(
					memberID,
					onboardingData.user.account,
					onboardingData.user.id,
					onboardingData.user.firstName,
					onboardingData.user.lastName,
					onboardingData.user.isAdmin,
					onboardingData.user.manageFilters,
					onboardingData.user.readOnly,
					onboardingData.user.allowSharing
				);
				//Write our initial data to firebase

				//Create user record
				firebaseIO.setData(
					'users/',
					onboardingData.user.id,
					data,
					null,
					null,
					null
				);

				if (createMember) {
					// Create member will add the member to the group
					//Check if the group we are adding member to already exists
					firebaseIO.getData(
						'groups/' + onboardingData.user.groupID + '/',
						createGroup,
						null,
						null
					);
				} else if (addAsMember) {
					// When adding as a member we don't need to create a new group
					userDataComplete();
				} else {
					//If we are just adding a new user and not adding them as a member of a current group then just create the group for just this user
					firebaseIO.setData(
						'groups/',
						onboardingData.user.id,
						groupData,
						userDataComplete,
						null,
						null
					);
				}

				//Callback function for creating groups after checking if it exists
				function createGroup(result) {
					//No existing group found, create new one
					if (!result) {
						//Switch the group id to the one passed in so we get the matching ID here
						groupData.group.id = onboardingData.user.groupID;
						firebaseIO.setData(
							'groups/',
							onboardingData.user.groupID,
							groupData,
							userDataComplete,
							null,
							null
						);
					}
					//Group found add this as member of that group
					else {
						firebaseIO.setData(
							'groups/' +
								onboardingData.user.groupID +
								'/members/',
							memberID,
							groupData.members[memberID],
							userDataComplete,
							null,
							null
						);
					}
				}

				function userDataComplete() {
					if (callback) {
						callback({
							user: data.user,
							group: groupData.group,
							activation: groupData.settings.activation,
						});
					}
				}
			}
		}

		//Update share data properties
		function setShareData(
			path,
			property,
			data,
			update,
			callback,
			mutate,
			groupData,
			userOnly
		) {
			var fullPath = path ? 'shares/' + path : 'shares';
			var promises = [];

			var deferredShareRequest = $q.defer();
			var deferredGroupRequest = $q.defer();
			promises.push(deferredShareRequest.promise);
			promises.push(deferredGroupRequest.promise);

			if (update) {
				firebaseIO.updateData(
					fullPath,
					property,
					data,
					sharePromiseSuccessCallback,
					sharePromiseFailureCallback,
					null
				);
			} else {
				firebaseIO.setData(
					fullPath,
					property,
					data,
					sharePromiseSuccessCallback,
					sharePromiseFailureCallback,
					null
				);
			}
			if (!path) {
				if (userOnly || !data) {
					firebaseIO.setUserData(
						null,
						'shares',
						property,
						groupData,
						update,
						groupPromiseCallback,
						null
					);
				}
				if (!userOnly || !data) {
					firebaseIO.setGroupData(
						'shares',
						property,
						groupData,
						update,
						groupPromiseCallback,
						null
					);
				}
			} else {
				groupPromiseCallback();
			}

			$q.all(promises).then(
				function (result) {
					if (callback) {
						callback(result);
					}
				},
				function (error) {
					if (callback) {
						callback(error);
					}
				}
			);

			function sharePromiseSuccessCallback(result) {
				deferredShareRequest.resolve(result);
			}
			function sharePromiseFailureCallback(error) {
				deferredShareRequest.reject(error);
			}
			function groupPromiseCallback(error) {
				if (error) {
					deferredGroupRequest.reject(error);
				} else {
					deferredGroupRequest.resolve();
				}
			}
		}

		//Update share data properties
		function setEvents(
			operation,
			events,
			update,
			callback,
			mutate,
			shareData,
			progressCallback,
			depricatedEventID
		) {
			var useStaticData = seedcodeCalendar.get('useStaticData');

			if (useStaticData) {
				callback();
				return;
			}

			var groupID = user.group.id;
			var fullPath = 'events/' + groupID;
			var eventCount = events.length;

			//Loop through all events passed in - Return with nothing if we don't pass in any events
			if (!events || !eventCount) {
				callback(null);
				return;
			}
			var promises = [];
			var counter = 0;
			var progressCounter = 0;
			var delay = 0;

			events.forEach(function (event) {
				// var promises = events.map(function(event) {
				var deferredEventRequest = $q.defer();
				var deferredShareRequest = $q.defer();
				var sourceTypeID = event.sourceTypeID;
				var eventSource = event.eventSource; //event.sourceTypeID;
				var eventID = event.eventID;
				var share;
				counter++;

				if (counter % 5 === 0) {
					delay += 60;
				}

				if (shareData) {
					if (!Array.isArray(shareData)) {
						shareData = [shareData];
					}

					share = {};

					for (var i = 0; i < shareData.length; i++) {
						share[shareData[i].id] = {id: shareData[i].id};
					}
				}

				if (operation === 'delete') {
					event = null;
				}
				for (var objProperty in event) {
					//Make sure we don't allow undefined as firebase will produce an error.
					if (
						event[objProperty] === undefined ||
						event[objProperty] !== event[objProperty]
					) {
						event[objProperty] = null;
					}
				}
				promises.push(deferredEventRequest.promise);
				promises.push(deferredShareRequest.promise);

				// Adding a delay to this so we fix a bug introduced in Safari 10.1. The bug causes an error on websocket connections when sending a lot of data. https://stackoverflow.com/questions/43194869/how-to-work-around-safari-10-1-error-failed-to-send-websocket-frame
				window.setTimeout(function () {
					firebase
						.database()
						.ref(fullPath)
						.child(
							utilities.generateEventID(
								sourceTypeID,
								eventSource,
								eventID,
								depricatedEventID
							)
						)
						.child('event')
						.set(event)
						.then(function () {
							progressCounter++;
							var progressValue = progressCounter / eventCount;

							deferredEventRequest.resolve(event);
							if (progressCallback) {
								progressCallback(progressValue);
							}
						})
						.catch(function (error) {
							deferredEventRequest.reject(false);
						});

					//Add any referenced shares to this event
					if (shareData) {
						firebase
							.database()
							.ref(fullPath)
							.child(
								utilities.generateEventID(
									sourceTypeID,
									eventSource,
									eventID,
									depricatedEventID
								)
							)
							.child('shares')
							.update(share)
							.then(function () {
								deferredShareRequest.resolve();
							})
							.catch(function (error) {
								deferredShareRequest.reject(false);
							});
					}
				}, delay);
			});

			$q.all(promises).then(
				function (result) {
					callback(result);
				},
				function (error) {
					callback(error);
				}
			);
		}

		function setEventData(path, property, data, update, callback, mutate) {
			var groupID = user.group.id;
			var fullPath = path
				? 'events/' + groupID + '/' + path
				: 'events/' + groupID;

			if (update) {
				firebaseIO.updateData(
					fullPath,
					property,
					data,
					callback,
					null,
					null
				);
			} else {
				firebaseIO.setData(
					fullPath,
					property,
					data,
					callback,
					null,
					null
				);
			}
		}

		function getShareData(path, callback, mutate) {
			//path is shareid
			var fullPath = 'shares/' + path;
			// Get the data on a post that has changed
			firebaseIO.getData(fullPath, callback, null, mutate);
		}

		function getEventData(property, start, end, events, callback, mutate) {
			//property could be 'event', or 'shares'
			var getUser = userDeferred.promise;
			getUser.then(function (result) {
				executeRequest();
			});

			function executeRequest() {
				var groupID = user.group.id;
				var fullPath = 'events/' + groupID;

				//Loop through all events passed in and
				if (!events || !events.length) {
					callback(null);
				}

				var promises = events.map(function (eventID) {
					var deferredRequest = $q.defer();
					var useStaticData = seedcodeCalendar.get('useStaticData');

					if (useStaticData) {
						firebaseIO.getStaticData(
							fullPath + '/' + eventID + '/' + property,
							function (result) {
								var output = mutate
									? mutate(result, eventID)
									: result;
								deferredRequest.resolve(output);
							},
							function (error) {
								deferredRequest.resolve(null);
							},
							null
						);
						return deferredRequest.promise;
					}

					firebase
						.database()
						.ref(fullPath)
						.child(eventID)
						.child(property)
						.once('value')
						.then(function (data) {
							//Apply data mutations
							var output = mutate
								? mutate(data.val(), eventID)
								: data.val();
							deferredRequest.resolve(output);
						})
						.catch(function (error) {
							// deferredRequest.reject(false); //We don't want to error if the event is not found as it might be a deleted event
							deferredRequest.resolve(null);
						});
					return deferredRequest.promise;
				});

				$q.all(promises).then(
					function (result) {
						callback(result);
					},
					function (error) {
						callback(error);
					}
				);
			}
		}

		function setNotificationUserData(
			path,
			property,
			data,
			update,
			callback,
			mutate
		) {
			var userIdentifier = getNotificationUserID();
			if (!userIdentifier) {
				return;
			}
			var fullPath = path
				? 'notificationUsers/' + userIdentifier + '/' + path
				: 'notificationUsers/' + userIdentifier;

			if (update) {
				firebaseIO.updateData(
					fullPath,
					property,
					data,
					callback,
					null,
					null
				);
			} else {
				firebaseIO.setData(
					fullPath,
					property,
					data,
					callback,
					null,
					null
				);
			}
		}

		function getNotificationUserData(path, callback, mutate) {
			var userIdentifier = getNotificationUserID();
			if (!userIdentifier) {
				return;
			}
			var fullPath = 'notificationUsers/' + userIdentifier + '/' + path;
			// Get the data on a post that has changed
			firebaseIO.getData(fullPath, callback, null, mutate);
		}

		function getNotificationUserID() {
			var config = seedcodeCalendar.get('config');
			var userID;

			userID = user.id;

			return userID;
		}

		function getUnscheduled(callback, mutate) {
			var groupID = user.group.id;
			var fullPath = 'unscheduled/' + groupID;
			// Get the data on a post that has changed
			firebaseIO.getData(fullPath, callback, null, mutate);
		}

		function setUnscheduled(id, data, update, callback) {
			var groupID = user.group.id;
			var fullPath = 'unscheduled/' + groupID;
			if (update) {
				firebaseIO.updateData(fullPath, id, data, callback, null, null);
			} else {
				firebaseIO.setData(fullPath, id, data, callback, null, null);
			}
		}
	}

	function manageSettings(
		$sce,
		seedcodeCalendar,
		daybackIO,
		firebaseIO,
		csvData,
		utilities,
		dataStore,
		environment
	) {
		return {
			initializeOnlineStatus: initializeOnlineStatus,
			setRemoteDeviceSignOut: setRemoteDeviceSignOut,
			getSessionCount: getSessionCount,
			getSessions: getSessions,
			setSession: setSession,
			cleanHorizonSlider: cleanHorizonSlider,
			setSignInDate: setSignInDate,
			getUserSettings: getUserSettings,
			setUserSettings: setUserSettings,
			setGroupSettings: setGroupSettings,
			setSourceTypeSettings: setSourceTypeSettings,
			settingsToConfig: settingsToConfig,
			userSettingsToConfig: userSettingsToConfig,
			viewSettingsToConfig: viewSettingsToConfig,
			applyViewSettings: applyViewSettings,
			applyConfigItem: applyConfigItem,
			applySourceItem: applySourceItem,
			setHash: setHash,
			getActivation: getActivation,
			setActivation: setActivation,
			getGroupMembers: getGroupMembers,
			setGroupMembers: setGroupMembers,
			getGroupMemberData: getGroupMemberData,
			setGroupMemberData: setGroupMemberData,
			getSources: getSources,
			setCalendarName: setCalendarName,
			setBackgroundColor: setBackgroundColor,
			getGroupInfo: getGroupInfo,
			getSettings: getSettings,
			getResources: getResources,
			getStatuses: getStatuses,
			getRawFilterData: getRawFilterData,
			getTranslations: getTranslations,
			setTranslations: setTranslations,
			getCalendarActions: getCalendarActions,
			setCalendarActions: setCalendarActions,
			updateStatus: updateStatus,
			updateResource: updateResource,
			updateFilterData: updateFilterData,
			nameToFilterItem: nameToFilterItem,
			mutateFilterField: mutateFilterField,
			mutateFilterFieldList: mutateFilterFieldList,
			filterFieldSort: filterFieldSort,
			applyShareSettings: applyShareSettings,
		};

		function initializeOnlineStatus(isShare) {
			daybackIO.initializeOnlineStatus(isShare);
		}

		function setRemoteDeviceSignOut(
			groupID,
			userID,
			deviceIDs,
			signOutSelf,
			callback
		) {
			var deviceIDResult = {};

			for (var i = 0; i < deviceIDs.length; i++) {
				// remove session record. This prevents orphaned session records and a new one will be automatically created when
				// a new one is neeeded.
				setSession(groupID, userID, deviceIDs[i], null, null);
				deviceIDResult[deviceIDs[i]] = deviceIDs[i];
			}

			if (signOutSelf) {
				daybackIO.signOut();
				if (callback) {
					callback();
				}
				return;
			}

			firebaseIO.setData(
				'sessions/' + groupID + '/' + userID,
				'forceSignOut',
				deviceIDResult,
				function (result) {
					if (callback) {
						callback(result);
					}
				},
				function (error) {
					if (callback) {
						callback(null);
					}
				},
				null
			);
		}

		function getSessionCount(groupID, userID, callback) {
			firebaseIO.getData(
				'sessions/' + groupID + '/' + userID + '/activeSessions',
				function (result) {
					if (callback) {
						callback(result);
					}
				},
				function (error) {
					if (callback) {
						callback(null);
					}
				},
				null
			);
		}

		function getSessions(groupID, userID, callback) {
			firebaseIO.getData(
				'sessions/' + groupID + '/' + userID + '/session',
				function (result) {
					if (callback) {
						callback(result);
					}
				},
				function (error) {
					if (callback) {
						callback(null);
					}
				},
				null
			);
		}

		function setSession(groupID, userID, deviceID, value, callback) {
			daybackIO.setSession(groupID, userID, deviceID, value, callback);
		}

		function cleanHorizonSlider(sliderValue, horizonDays, share) {
			var isShare = seedcodeCalendar.get('share');
			share = !share ? isShare : share;
			var sliderValueResult;
			var mobileSliderLimit = 208;
			var mobileDaysLimit = 1095800; // 30 Centuries

			if (share && !!share.isPhone !== environment.isPhone) {
				var rangeStart = moment(share.start);
				var rangeEnd = moment(share.end);
				var rangeDays = rangeEnd.diff(rangeStart, 'days');
				sliderValueResult =
					$.fullCalendar.horizonDaysToSlider(rangeDays);
				sliderValueResult = Math.min(
					sliderValueResult,
					mobileSliderLimit
				);

				if (!isShare) {
					dataStore.saveState('horizonSlider', sliderValueResult);
				}
			} else {
				var sliderToDays =
					$.fullCalendar.horizonSliderToDays(sliderValue);

				if (
					(!share &&
						horizonDays &&
						!dataStore.getState('horizonSlider')) ||
					(environment.isPhone &&
						(horizonDays > mobileDaysLimit ||
							sliderToDays > mobileDaysLimit))
				) {
					// Make sure we don't exceed the maximum value for horizon days on small mobile devices
					if (
						environment.isPhone &&
						(horizonDays > mobileDaysLimit ||
							sliderToDays > mobileDaysLimit)
					) {
						horizonDays = mobileDaysLimit;
					}

					sliderValueResult =
						$.fullCalendar.horizonDaysToSlider(horizonDays);

					// Update local storage values if they are set
					if (dataStore.getState('horizonDays')) {
						dataStore.saveState('horizonSlider', sliderValueResult);
						dataStore.clearState('horizonDays');
					}
				} else {
					sliderValueResult = sliderValue;
				}
			}
			return sliderValueResult;
		}

		async function setSignInDate() {
			try {
				return await firebaseIO.setUserData(
					null,
					'',
					'lastSignIn',
					new Date().valueOf()
				);
			} catch (err) {
				throw err;
			}
		}

		async function getUserSettings(callback) {
			return await firebaseIO.getUserData(null, 'settings', callback);
		}

		function setUserSettings(name, data, callback) {
			var path = name ? 'settings' : '';
			var property = name ? name : 'settings';
			firebaseIO.setUserData(null, path, property, data, true, callback);
		}

		function setGroupSettings(property, data, update, callback) {
			firebaseIO.setGroupData(
				'settings',
				property,
				data,
				update,
				callback
			);
		}

		function setSourceTypeSettings(sourceType, property, data, callback) {
			firebaseIO.setGroupData(
				'settings/sourceTypes/' + sourceType,
				property,
				data,
				false,
				callback
			);
		}

		//Initialize view setting information to the view setting config based on each item in config map (create defaults)
		function initViewSetting(config, configItem) {
			var name = configItem.setting;
			var value = config[name];
			if (configItem.views && Array.isArray(configItem.views)) {
				//Set view setting default for the setting
				if (!config.viewSettings) {
					config.viewSettings = {};
				}
				if (!config.viewSettings.defaults) {
					config.viewSettings.defaults = {};
				}

				config.viewSettings.defaults[name] = value;

				// Set our value to view settings - currently not being used as we do not have default settings yet (should come from admin settings)
				// for (var i = 0; i < configItem.views.length; i++) {
				//   if (!config.viewSettings[configItem.views[i]]) {
				//     config.viewSettings[configItem.views[i]] = {};
				//   }
				//   config.viewSettings[configItem.views[i]][name] = value;
				// }
			}
		}

		//Get saved settings and add them to our config - This should run once at startup (during init process after other setting initializations have occurred)
		function viewSettingsToConfig(config) {
			var configMap = seedcodeCalendar.calendar.configMap;
			var share = seedcodeCalendar.get('share');
			var views = configMap.view.options;
			var view;
			var viewSettings;

			for (var property in views) {
				view = views[property].id;

				try {
					viewSettings = share
						? share.settings.viewSettings[view]
						: JSON.parse(dataStore.getState(view));
				} catch (error) {
					viewSettings = null;
					return;
				}

				for (var property in viewSettings) {
					if (!config.viewSettings[view]) {
						config.viewSettings[view] = {};
					}
					config.viewSettings[view][property] =
						viewSettings[property];
				}
			}

			applyViewSettings(config, config.defaultView, true);
		}

		function applyViewSettings(config, view, init) {
			if (config.viewSettings) {
				//Set default view values
				for (var property in config.viewSettings.defaults) {
					config[property] = config.viewSettings.defaults[property];
					if (!init) {
						seedcodeCalendar
							.get('element')
							.fullCalendar(
								'updateOptions',
								property,
								config[property],
								false
							);
					}
				}
				//Overwrite the default view values with any user specified values
				for (var property in config.viewSettings[view]) {
					config[property] = config.viewSettings[view][property];
					if (!init) {
						seedcodeCalendar
							.get('element')
							.fullCalendar(
								'updateOptions',
								property,
								config[property],
								false
							);
					}
				}
			}
		}

		function settingsToConfig(config) {
			var configMap = seedcodeCalendar.calendar.configMap;
			var settings = seedcodeCalendar.get('settings');
			var share = seedcodeCalendar.get('share');
			for (var configItem in configMap) {
				applyConfigItem(
					config,
					settings,
					configMap[configItem],
					configItem,
					false,
					share
				);
				initViewSetting(config, configMap[configItem]);
			}
		}

		function userSettingsToConfig(config) {
			var configMap = seedcodeCalendar.calendar.userConfigMap;
			var settings = seedcodeCalendar.get('userSettings');
			for (var configItem in configMap) {
				applyConfigItem(
					config,
					settings,
					configMap[configItem],
					configItem
				);
				initViewSetting(config, configMap[configItem]);
			}
		}

		function applyShareSettings(config, share, shareSettingsOverride) {
			var shareConfig = share.settings;
			var sidebar = seedcodeCalendar.get('sidebar');
			var configMap = seedcodeCalendar.calendar.configMap;
			var userConfigMap = seedcodeCalendar.calendar.userConfigMap;
			var stateConfigMap = seedcodeCalendar.calendar.stateConfigMap;
			var configItem;

			// Set default date based on defaultDatePreference
			if (share.defaultDatePreference && share.shareWith !== 'public') {
				const dateFormat = 'YYYY-MM-DD';
				const now = moment();

				switch (share.defaultDatePreference) {
					case 'currentDate':
						delete shareConfig.defaultDate;
						break;
					case 'firstOfWeek':
						const dayDiff =
							Number(config.firstDay || 0) - now.day();
						shareConfig.defaultDate = now
							.clone()
							.add(dayDiff, 'days')
							.format(dateFormat);
						break;
					case 'firstOfMonth':
						shareConfig.defaultDate = now
							.clone()
							.startOf('month')
							.format(dateFormat);
						break;

					case 'firstOfQuarter':
						shareConfig.defaultDate = now
							.clone()
							.startOf('quarter')
							.format(dateFormat);
						break;
					case 'firstOfYear':
						shareConfig.defaultDate = now
							.clone()
							.startOf('year')
							.format(dateFormat);
						break;
				}
			}

			for (var property in shareConfig) {
				configItem = null;

				if (shareSettingsOverride && shareSettingsOverride[property]) {
					continue;
				}

				if (property === 'defaultDate') {
					dataStore.saveState('date', shareConfig[property]);
				}

				if (property === 'viewSettings') {
					config[property] = JSON.parse(
						JSON.stringify(shareConfig[property])
					);
				} else if (config[property] !== shareConfig[property]) {
					if (configMap[property]) {
						configItem = configMap[property];
					} else if (userConfigMap[property]) {
						configItem = userConfigMap[property];
					} else if (stateConfigMap && stateConfigMap[property]) {
						configItem = stateConfigMap[property];
					}
					if (configItem && configItem.saved) {
						if (
							typeof shareConfig[property] === 'object' &&
							shareConfig[property] !== null
						) {
							dataStore.saveState(
								configItem.saved,
								JSON.stringify(shareConfig[property])
							);
						} else {
							dataStore.saveState(
								configItem.saved,
								shareConfig[property]
							);
						}
					}

					config[property] = shareConfig[property];
				} else {
					config[property] = shareConfig[property];
				}
			}

			// Don't allow the map for public shares
			if (share.shareWith === 'public') {
				config.mapEnabled = false;
			}

			//Restore sidebar state
			if (sidebar && share && share.sidebar) {
				sidebar.show = share.sidebar.show;
				sidebar.view = share.sidebar.view;
				dataStore.saveState('sidebarTab', sidebar.view);
				dataStore.saveState('showSidebar', sidebar.show);
			}

			// Restore alt-view state
			seedcodeCalendar.init('alt-view', {
				enabled: config.unscheduledEnabled || config.mapEnabled,
				show:
					(config.unscheduledEnabled || config.mapEnabled) &&
					config.showAltView,
				type:
					!config.mapEnabled && config.altViewType === 'map'
						? 'unscheduled'
						: !config.unscheduledEnabled &&
							  config.altViewType === 'unscheduled'
							? 'map'
							: config.altViewType,
				width: config.altViewWidth,
			});

			applyViewSettings(config, config.defaultView, true);
		}

		function applyConfigItem(
			config,
			settings,
			configItem,
			name,
			clearSaved,
			share
		) {
			//Make sure we received a config item and setting to read
			if (!settings || !configItem) {
				return;
			}
			var savedState;
			var setting = configItem.mutate
				? configItem.mutate(settings[configItem.setting])
				: settings[configItem.setting];
			var defaultValue = configItem.mutate
				? configItem.mutate(configItem.defaultValue)
				: configItem.defaultValue;
			var value;

			//Check to see if there is a valid share and setting, if so use that
			if (
				share &&
				share.settings.hasOwnProperty(configItem.setting) &&
				((environment.isPhone &&
					configItem.setting !== 'resourceColumns' &&
					configItem.setting !== 'resourceDays' &&
					configItem.setting !== 'horizonDays' &&
					configItem.setting !== 'horizonSlider') ||
					!environment.isPhone)
			) {
				savedState = share.settings[configItem.setting];
			} else if (clearSaved && configItem.saved) {
				dataStore.clearState(configItem.saved);
			} else if (configItem.saved) {
				savedState = dataStore.getState(configItem.saved);

				if (configItem.json) {
					savedState = JSON.parse(savedState);
				}
			}

			//Change saved state to string if we are dealing with a boolean value
			if (savedState === true || savedState === false) {
				savedState = savedState.toString();
			}

			//Change setting to string if we are dealing with a boolean value
			if (setting === true || setting === false) {
				setting = setting.toString();
			}

			value = savedState || setting || defaultValue;

			//Change strings back to boolean if necessary
			if (value === 'true' || value === 'false') {
				value = value === 'true' ? true : false;
			}
			config[name] = value === '~empty~' ? '' : value;
		}

		function applySourceItem(source, settings) {
			// Sets source settings retrieved into source object
			//Make sure we received a config item and setting to read
			if (!source || !settings) {
				return;
			}
			var configItem;
			var setting;
			var defaultValue;
			var value;

			for (var property in settings) {
				configItem = settings[property];
				setting = configItem.mutate
					? configItem.mutate(source[configItem.setting])
					: source[configItem.setting];
				defaultValue = configItem.mutate
					? configItem.mutate(configItem.defaultValue)
					: configItem.defaultValue;

				//Change setting to string if we are dealing with a boolean value
				if (setting === true || setting === false) {
					setting = setting.toString();
				}
				value = setting || defaultValue;
				//Change strings back to boolean if necessary
				if (value === 'true' || value === 'false') {
					value = value === 'true' ? true : false;
				}

				source[property] = value;
			}
		}

		function getGroupMembers(callback) {
			firebaseIO.getGroupData('members', callback);
		}

		function setGroupMembers(members, callback) {
			//Members should be a members object
			firebaseIO.setGroupData('', 'members', members, true, callback);
		}

		function getGroupMemberData(callback) {
			var user;
			var memberID;

			user = daybackIO.getUser();
			memberID = user.group.memberID;
			firebaseIO.getGroupData('members/' + memberID, callback);
		}

		function setGroupMemberData(property, value, callback) {
			var user = daybackIO.getUser();
			var memberID = user.group.memberID;
			firebaseIO.setGroupData(
				'members/' + memberID,
				property,
				value,
				false,
				callback
			);
		}

		function setBackgroundColor(color, schedule, callback) {
			var scheduleKey = utilities.stringToID(schedule.id);
			var sourceID =
				schedule.sourceID && !schedule.sourceTemplate.localSchedules
					? schedule.sourceID
					: schedule.id;

			getSources(setSourceData);
			function setSourceData(sources) {
				for (var i = 0; i < sources.length; i++) {
					if (sources[i].id === sourceID) {
						if (
							schedule.sourceTemplate.seperateSchedules &&
							!schedule.sourceTemplate.localSchedules
						) {
							firebaseIO.setGroupData(
								'sources/' + sources[i].id,
								scheduleKey,
								{backgroundColor: color},
								true,
								callback
							);
						} else {
							firebaseIO.setGroupData(
								'sources',
								sources[i].id,
								{backgroundColor: color},
								true,
								callback
							);
						}
						break;
					}
				}
			}
		}

		function setCalendarName(name, schedule, callback) {
			getSources(setSourceData);
			function setSourceData(sources) {
				for (var i = 0; i < sources.length; i++) {
					if (sources[i].id === schedule.id) {
						firebaseIO.setGroupData(
							'sources',
							sources[i].id,
							{name: name},
							true,
							callback
						);
						break;
					}
				}
			}
		}

		function getSources(callback) {
			daybackIO.getSources(processSources);

			function processCSVSources(sources) {
				var output = csvData.csvToSources(sources);
				callback(output);
			}

			function processSources(sources) {
				var output = [];
				for (var property in sources) {
					output.push(sources[property]);
				}
				callback(output);
			}
		}

		/** @type {(property?: string, callback?: Function) => void} */
		function getGroupInfo(property, callback) {
			const path = property ? `group/${property}` : 'group';
			firebaseIO.getGroupData(path, callback);
		}

		function getSettings(callback) {
			function processCSVSettings(data) {
				var settings = csvData.csvToSettings(data);
				callback(settings);
			}

			firebaseIO.getGroupData('settings', callback);
		}

		function getTranslations(id, callback) {
			firebaseIO.getGroupData('translations/' + id, callback);
		}

		function setTranslations(id, translation, callback) {
			//Members should be a members object
			firebaseIO.setGroupData(
				'translations',
				id,
				translation,
				true,
				callback
			);
		}

		function getCalendarActions(callback) {
			firebaseIO.getGroupData('calendarActions', processActions);
			function processActions(actions) {
				// Assign category to actions
				for (var property in actions) {
					actions[property].category = 'app';
				}
				if (callback) {
					callback(actions);
				}
			}
		}

		function setCalendarActions(id, action, callback) {
			//Action should be an actions object
			firebaseIO.setGroupData(
				'calendarActions',
				id,
				action,
				true,
				callback
			);
		}

		function setHash(
			hash,
			restoreDirectory,
			upgradeDirectory,
			subscriptionURL,
			platform
		) {
			firebaseIO.setGroupData(
				'',
				'settings',
				{activationHash: hash},
				true,
				null,
				null
			);
		}

		function getActivation(callback) {
			firebaseIO.getGroupData(
				'settings/activation',
				function (activation) {
					var settings = seedcodeCalendar.get('settings');
					settings.activation = activation;
					if (callback) {
						callback(activation);
					}
				}
			);
		}

		function setActivation(property, value, callback) {
			var data = {};

			if (!property || property === 'activation') {
				data = value;
			} else {
				data[property] = value;
			}

			firebaseIO.setGroupData(
				'settings',
				'activation',
				data,
				true,
				callback
			);
		}

		function getRawFilterData(type, callback) {
			if (type === 'statuses' || type === 'resources') {
				firebaseIO.getGroupData(type, callback);
			} else {
				callback({error: type + ' not available'});
			}
		}

		function getResources(callback) {
			const share = seedcodeCalendar.get('share');
			if (share?.filters?.resources) {
				callback(
					mutateFilterFieldList(share.filters.resources, null, true)
				);
			} else {
				firebaseIO.getGroupData(
					'resources',
					callback,
					mutateFilterFieldList,
					null
				);
			}
		}

		function updateStatus(data, name, operation, callback) {
			var originalName;
			var id;
			var color;

			firebaseIO.setGroupData(
				'',
				'statuses',
				data,
				true,
				processStatuses
			);

			function processStatuses(result) {
				if (callback) {
					callback(mutateFilterFieldList(result, null, true, false));
				}
			}
			function processCSVStatuses(statusesCSV) {
				var result = mutateFilterFieldList(
					csvData.csvToStatuses(statusesCSV)
				);
				if (callback) {
					callback(result);
				}
			}
		}
		function updateResource(data, operation, callback) {
			firebaseIO.setGroupData(
				'',
				'resources',
				data,
				true,
				processResources
			);

			function processResources(result) {
				if (callback) {
					callback(mutateFilterFieldList(result, null, true, false));
				}
			}
		}

		function updateFilterData(type, filterID, data, callback) {
			firebaseIO.setGroupData(
				type,
				filterID,
				data,
				true,
				processResources
			);

			function processResources(result) {
				if (callback) {
					callback(result);
				}
			}
		}

		function getStatuses(callback) {
			const share = seedcodeCalendar.get('share');

			if (share?.filters?.statuses) {
				callback(
					mutateFilterFieldList(share.filters.statuses, null, true)
				);
			} else {
				firebaseIO.getGroupData(
					'statuses',
					callback,
					mutateFilterFieldList,
					null
				);
			}
		}

		function nameToFilterItem(name) {
			var filterItem = {};
			filterItem.name = name;
			filterItem.id = name;

			mutateFilterField(filterItem);

			return filterItem;
		}

		function mutateFilterField(item, stored) {
			var config = seedcodeCalendar.get('config');
			var defaultEventColor = config.defaultEventColor;
			if (item.isFolder && !item.id && item.folderID) {
				item.id = item.folderID;
			}
			if (!item.id) {
				item.id = utilities.stringToID(item.name);
			} else {
				item.id = utilities.stringToID(item.id);
			}
			if (!item.color) {
				item.color = defaultEventColor;
			}
			if (!item.nameClean) {
				item.nameSafe = utilities.filterSpecialChars(item.name);
			}
			if (!item.display) {
				item.display = item.name;
			}
			if (item.description) {
				item.descriptionDisplay = $sce.trustAsHtml(item.description);
			}
			if (!item.status) {
				item.status = {selected: false};
			}
			if (item.folderID) {
				item.folderID = utilities.stringToID(item.folderID);
			}
			if (stored) {
				item.status.stored = true;
			}
			if (item.tags) {
				// Comma separated list convert to array of objects including name and class properties
				item.tags = !Array.isArray(item.tags)
					? utilities.commaSeparatedToArray(item.tags, true, 'tag')
					: item.tags;
			}
			if (item.class) {
				item.class = utilities.stringToClass(item.class, 'dbkcustom');
			}
			item.capacity =
				item.capacity && !isNaN(item.capacity)
					? Number(item.capacity)
					: 0;

			return item;
		}

		function mutateFilterFieldList(
			data,
			noFilterLabel,
			preventNoFilter,
			preventSort,
			mutate,
			sortField,
			fromAction
		) {
			var config = seedcodeCalendar.get('config');
			var defaultEventColor = config.defaultEventColor;
			var dataFromArray = {};
			var result = [];
			var noFilterLabelFound;
			var arrayItem;
			var filterItem;
			var arrayItemID;
			if (!noFilterLabel) {
				noFilterLabel = config.noFilterLabel;
			}

			if (!data) {
				data = {};
			}
			if (Array.isArray(data)) {
				for (var i = 0; i < data.length; i++) {
					if (!data[i].name) {
						continue;
					}
					if (fromAction && !data[i].sort) {
						data[i].sort = i + 1;
					}

					arrayItem = mutateFilterField(data[i], !fromAction);
					arrayItemID =
						dataFromArray[arrayItem.id] && !arrayItem.isFolder
							? arrayItem.id + '-dup-' + i
							: arrayItem.id;
					dataFromArray[arrayItemID] = arrayItem;
				}
				data = dataFromArray;
			}

			for (var property in data) {
				if (!data[property].name) {
					continue;
				}

				filterItem = cleanFilterItem(
					data,
					property,
					mutate,
					!fromAction
				);

				if (filterItem.name === noFilterLabel) {
					noFilterLabelFound = true;
				}

				result.push(filterItem);
			}

			//Add our undefined filter name
			if (
				!fromAction &&
				noFilterLabel &&
				!noFilterLabelFound &&
				!preventNoFilter
			) {
				result.unshift({
					id: utilities.generateUID(),
					name: noFilterLabel,
					shortName: 'n/a',
					nameSafe: utilities.filterSpecialChars(noFilterLabel),
					color: defaultEventColor,
					status: {selected: false},
				});
			}

			if (preventSort) {
				return result;
			} else {
				return filterFieldSort(result, noFilterLabel, sortField);
			}
		}

		function cleanFilterItem(itemList, property, mutate, stored) {
			var item = itemList[property];
			var folderID;
			// var folderItemSort;
			// var folderSortValue;

			if (!item || !item.name) {
				return;
			}

			mutateFilterField(item, stored);

			//Check if filter item is part of a folder and add folder name
			if (item.folderID) {
				folderID = item.folderID;
				// Check if this item's parent folder exists
				if (itemList[folderID]) {
					item.folderName = itemList[folderID].name;
					// Create a value to sort folders by
					item.folderSortValue = itemList[folderID].sortOverride
						? itemList[folderID].sortOverride
						: itemList[folderID].sort;
					// Create a text based string to sort on based on the folder ID (this will keep all items in a folder together
					item.folderSortID = utilities.filterSpecialChars(
						item.folderID,
						true,
						true
					);
				} else {
					item.folderID = null;
				}
			}
			if (mutate) {
				item.mutate = mutate;
			}
			return item;
		}

		function filterFieldSort(filterItems, noFilterLabel, sortField) {
			return filterItems.sort(function (a, b) {
				return sortOrder(a, b, filterItems, noFilterLabel, sortField);
			});

			function sortOrder(a, b, filterItems, noFilterLabel, sortField) {
				// Set folder sort value to it's own sort if it's a folder,
				// otherwise set the sort value to the parent folder

				var aNumericSort = a.sortOverride
					? a.sortOverride || ''
					: a.sort || '';
				var bNumericSort = b.sortOverride
					? b.sortOverride || ''
					: b.sort || '';

				if (!sortField) {
					sortField = a.nameSafe && b.nameSafe ? 'nameSafe' : 'name';
				}

				// a first === a number less than zero
				// b first === a number greater than zero
				// unchanged === 0
				if (!noFilterLabel) {
					noFilterLabel =
						seedcodeCalendar.get('config').noFilterLabel;
				}

				if (a.name === noFilterLabel) {
					return -1;
				} else if (b.name === noFilterLabel) {
					return 1;
				}

				var aIsFolder = a.isFolder ? 'a' : 'z';
				var bIsFolder = b.isFolder ? 'a' : 'z';

				var aStringCompare = a.folderID
					? a.folderSortValue +
						a.folderSortID +
						aIsFolder +
						aNumericSort +
						'a' + // Separator if the sort field contains a number it isn't appended to numeric sort
						a[sortField].toLowerCase()
					: aNumericSort + 'a' + a[sortField].toLowerCase();

				var bStringCompare = b.folderID
					? b.folderSortValue +
						b.folderSortID +
						bIsFolder +
						bNumericSort +
						'a' + // Separator if the sort field contains a number it isn't appended to numeric sort
						b[sortField].toLowerCase()
					: bNumericSort + 'a' + b[sortField].toLowerCase();

				var stringCompare = aStringCompare.localeCompare(
					bStringCompare,
					undefined,
					{numeric: true, sensitivity: 'base'}
				);

				return stringCompare;
			}
		}
	}
})();
